import { render, staticRenderFns } from "./SendEventMemberLinkModalV2.vue?vue&type=template&id=6c997bef&scoped=true&"
import script from "./SendEventMemberLinkModalV2.vue?vue&type=script&lang=js&"
export * from "./SendEventMemberLinkModalV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c997bef",
  null
  
)

export default component.exports