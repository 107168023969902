<template>
  <div>
    <b-modal
      id="send-event-member-links-modal-v2"
      title="Send Links"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="md"
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="errorMessage" class="alert alert-danger">Failed to send : {{ errorMessage }}</div>
        <p>Are you sure you want to send links to {{ selectedEventMembers.length }} event members for this raffle?</p>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import MessageCenter from '@/lib/message-center';

export default {
  props: ['event', 'selectedEventMembers'],

  data() {
    return {
      modalShow: false,
      loading: false,
      errorMessage: null,
      eventMember: []
    };
  },

  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    async resetForm() {
      this.eventMember = [];

      this.errorMessage = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async sendLinkToEventMembers() {
      this.loading = true;

      try {
        await MessageCenter.sendEventMemberLink(this.event.id, this.selectedEventMembers);

        this.modalShow = false;
      } catch (error) {
        const message = error.response?.data?.errors?.[0].message;
        if (message) {
          this.errorMessage = `Unable to send links - ${message}`;
        } else {
          this.errorMessage = `An unknown error occurred while sending links.`;
        }
      }
      this.loading = false;
    },

    async onSubmit() {
      const formValid = await this.$validator.validateAll();

      if (!formValid) {
        return;
      }

      await this.sendLinkToEventMembers();
    }
  }
};
</script>

<style scoped></style>
